import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Container } from '@material-ui/core'
import font from '@helpers/font'
import {
  H1,
  H5,
  BackgroundImage,
  MarkdownHandler,
  WistiaVideoOverlay,
  HangingQuote,
} from '@system'
import PlayCircleOutlineIcon from '@react-svgs/play_circle_outline.svg'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightLight,
      ...makeResponsiveFontSizes(59, 21),
      [theme.breakpoints.down('md')]: {
        ...makeResponsiveFontSizes(38, 21),
      },
      [theme.breakpoints.down('sm')]: {
        ...makeResponsiveFontSizes(38, 21),
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    description: {
      display: 'block',
      color: theme.palette.common.white,
      padding: '15px 40px 15px 0px',
      ...makeResponsiveFontSizes(21, 15),
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    backgroundImage: {
      width: '100%',
      height: '59vh',
      maxHeight: '600px',
      backgroundColor: theme.palette.primary.main,
      backgroundPosition: 'top center',
      [theme.breakpoints.down('lg')]: {
        maxHeight: 'unset',
        height: '500px',
      },
      [theme.breakpoints.down('md')]: {
        height: '340px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '206px',
        width: '100%',
      },
    },
    contentBox: {
      position: 'relative',
      paddingTop: '50px',
      width: (props) => `${props.blok.textProportion}%`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-end',
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-evenly',
        width: '100%',
        height: '75%',
      },
    },
    buttonContainer: {
      margin: '1rem 0 0.8rem',
    },
    button: {
      height: '42px',
      ...theme.typography.button,
      borderRadius: '0',
      boxShadow: 'none',
      fontSize: 13,
      fontWeight: 700,
      color: theme.palette.primary.main,
      background: theme.palette.background.slite,
      padding: '8px 18px',
      width: '187px',
    },
    link: {
      alignSelf: 'center',
      margin: '0px 16px',
      '& a': {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        '&:hover': {
          borderColor: theme.palette.common.white,
        },
      },
    },
    bottomHalf: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        marginTop: '10%',
        flexDirection: 'row',
        alignItems: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
  }
})

const ForegroundContent = (props) => (
  <Container fixed style={{ height: '100%', maxWidth: '95%' }}>
    <Box className={props.classes.contentBox}>
      <H1 component="h2" className={props.classes.title}>
        <HangingQuote>{props.blok.title}</HangingQuote>
      </H1>
    </Box>
    <Box display="flex" className={props.classes.bottomHalf}>
      <H5 className={props.classes.description}>
        <MarkdownHandler>{props.blok.description}</MarkdownHandler>
      </H5>
      <Button
        variant="contained"
        color="inherit"
        className={props.classes.button}
      >
        Watch the Video
        <PlayCircleOutlineIcon
          style={{ marginLeft: '11px', fontSize: '16px' }}
        />
        <WistiaVideoOverlay videoId={props.blok.videoId} />
      </Button>
    </Box>
  </Container>
)

const FeaturedVideoModule = (props) => {
  const classes = useStyles(props)
  const background = !!props.blok.backgroundImage && props.blok.backgroundImage
  return (
    <SbEditable content={props.blok}>
      <BackgroundImage className={classes.backgroundImage} image={background}>
        <ForegroundContent {...props} classes={classes} />
      </BackgroundImage>
    </SbEditable>
  )
}

export default FeaturedVideoModule
